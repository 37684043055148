#contactSection {
    background-color: #393e46;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    position: sticky;
  top: 0;
}

#contactForm {
    background-color: rgb(255, 255, 255);
    width: 80%; /* Adjusted width for better responsiveness */
    max-width: 800px; /* Set a maximum width for larger screens */
    padding: 20px; /* Added padding for better spacing */
    border-radius: 25px;
    margin: 20px; /* Added margin for better spacing */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.5);
}

#contactForm label
{
    font-weight: bold;
    color: rgb(50, 50, 50);
}

#contactForm  input
{
    border: none;
    background-color: rgb(210, 210, 210);
    height: 50px;
    width: 80%;
    border-radius: 10px;
    font-size: 18px;
    font-weight:bold;
    font-family:sans-serif;
    
}

#contactForm fieldset
{
    border: none;
    justify-content: space-between;
  position: relative;
  left: 10%;
}

#contactForm  textarea
{
    border: none;
    background-color: rgb(210, 210, 210);
    height: 200px;
    width: 80%;
    border-radius: 10px;

    padding-top: 10px;
    font-size: 18px;
    font-weight:bold;
    font-family:sans-serif;
    resize: none;
}
#submit-button
{
    height: 50px;
    width: 30%;
    border-radius: 10px;
    border: none;
    background-color:  #00a4d6;
    color: white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: bold;

    justify-content: space-between;
    position: relative;
    left: 50%;
}

#submit-button:hover
{
    color: black;
    cursor:pointer;
    transform: translateY(-0.25em);
}

#captcha
{
    margin-right: 60%;
    margin-bottom: 2%;
    transform: translateX(-40%);
    -webkit-transform: translateX(-40%);
    -moz-transform: translateX(-40%);
    -ms-transform: translateX(-40%);
    -o-transform: translateX(-40%);
    visibility: hidden !important;
}

#captcha-span
{
    margin-left: 50%;
    margin-bottom: 2%; 
}

#thankYouText
{
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center
}

input{
    text-indent: 10px;
}

textarea{
    text-indent: 10px;
}

@media (max-width: 768px) {
    /* Adjust styles for larger screens */
    #contactForm {
        width: 80%;
    }

    #captcha-span {
        margin-left: 0%; /* Reset margin for smaller screens */
        text-align: center; /* Center the content */
        font-size: 50%;
    }

    #contactForm  input
    {
        border: none;
        background-color: rgb(210, 210, 210);
        height: 50px;
        width: 70%;
    }

    #contactForm  textarea
    {
        width: 70%;
    }

    #submit-button
    {
        left: .5%;
    margin-left: 0;
    width: 70%;
    }

    #captcha-span
    {
        margin-left: -27%;
        margin-bottom: 2%; 
    }
}