#project{
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:1%;
    border-radius: 8px;
    flex-basis: 25%;
    flex-grow: 0;
    margin: 0px;
     /*might remove later*/
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

#project-img
{
    max-width: 200px;
    max-height: 200px;
    border-radius: 8px;
}

#project:hover
{
    background-color: rgb(67, 67, 67);
    
}

#project-details
{
    padding-left: 2%;
}

#project-summary
{
    display:none;
}

#project-viewButton
{
    height: 50px;
    width: 50%;
    border-radius: 10px;
    border: none;
    background-color:  #00a4d6;
    color: white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: bold;
    justify-content: space-between;
    position: relative;
    left: 25%;
    display: none;
}

#project-viewButton :hover
{
    color: black;
}

@media (max-width: 768px) {
    
    #project
    {
      width: 90%;
      height: 90%;
      flex-direction: column;
      padding-right: 0%;
      
    }

    #project-img
    {
        width: 100%;
        height: 100%;
    }

    #project-summary
    {
    display:block;
    }

    #project-viewButton
    {

        text-decoration: none;
        display:block;
    }

    #project-viewButton :hover
    {
    color: black;
    }

}