#home{
  scroll-margin-top: 10px;
    background-color: #393e46;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    max-width: 100%;
    padding-bottom: 20px;
    background-image: url('Images/abstract_background.png');
    background-size: 100% 100%;
    
  }

#aboutImage{
    height: auto;
    width: auto;
    max-width: 250px;
    max-height: 250px;
}

#project-button
{
    height: 50px;
    width: 10%;
    border-radius: 10px;
    border: none;
    background-color:  #00a4d6;
    color: white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: bold;

    justify-content: space-between;
    position: relative;
    left: 0%;
}

#project-button:hover
{
    color: black;
    cursor:pointer;
    transform: translateY(-0.25em);
}

.highlight
{
  text-shadow: 50%;
  color: #00a4d6;
}

#intro
{
  text-align: center;
}

@media (max-width: 768px) {
    

  #home
  {
  background-color: rgb(40, 40, 40);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 80px;
  text-align: center;
  background-size: 100% 100%;
  
  } 

  #project-button
  {
    width: 20%;
  }
}
