h1{
    color: white;
}

#portfolioDiv{
    background-color: #282828;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#portfolioSpan{
    background-color: rgb(40, 40, 40);
    display: flex;
    flex-direction: row;
}