#NavBar
{
    background-color: rgb(10, 10, 10);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.linkButtons {
    display: flex;
    align-items: center;
}


.scrollButton
{
    height: 60px;
    font-size: 20px;
    padding: 20px;
    border: 0;
    background: none;
    background-color: rgba(0, 0, 0, 0);
    color:white;
    opacity: 0.5;
    font-family:sans-serif;
}

.scrollButton:hover{
   color: #00a4d6;
   opacity: 1;
   cursor:pointer;
}

.navImage
{
    
    padding-right: 20px;
    height: auto; 
    width: auto; 
    max-width: 50px; 
    max-height: 50px;
    opacity: 0.5;
}

.navImage:hover{
    filter: invert(47%) sepia(97%) saturate(2286%) hue-rotate(162deg) brightness(95%) contrast(101%);
    opacity: 1;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100
  }

#logo
{
    display: flex;
    max-width: 100px; 
    max-height: 100px;
}

#hamburger-menu {
    display: none;
     /* Show hamburger menu on mobile */
 /* Adjust spacing */
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 85%;
    top: 25px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #00a4d6;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    bottom: 0%;
    overflow: hidden; 
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgb(10, 10, 10);
    padding:em 1.5em 0;
    font-size: 1.15em;
    
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    max-height: 90%;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    width: 100%;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
@media (max-width: 768px) {
    .scrollButtons,
    .linkButtons {
        display: none; /* Hide scroll and link buttons on desktop */
    }
    /* Show hamburger menu on mobile */
    #hamburger-menu {
        display: block; 
        right: 50%;
    }
}