#about{
  scroll-margin-top: 10px;
    background-color: rgb(57, 62, 70);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    padding-bottom: 20px;
    /*background-image: url("/—Pngtree—science geometric abstract background_336360.png");*/
  }

#aboutImage{
    height: auto;
    width: auto;
    max-width: 250px;
    max-height: 250px;
}

.circleImage{
  width: 200px;
  height: 200px;
  border-radius:50%;
  object-fit: cover;
}

#aboutDescription,
#aboutSkills {
  width: 100%; /* Use the full width of the container */
  max-width: 500px; /* Set a maximum width for better readability on larger screens */
  margin-right: auto; /* Center the element in the available space */
  margin-left: auto;
}

#aboutDescription {
  margin-right: 40px; /* Add some spacing between the two elements */
}

@media (max-width: 768px) {
  #aboutDescription,
  #aboutSkills {
    max-width: 100%; /* Full width on smaller screens */
    width: auto; /* Auto width for responsiveness */
    margin-right: 0; /* Reset right margin */
    right: 0; /* Reset the relative positioning */
  }
  
  #aboutSkills {
    right: 0; /* Reset the relative positioning */
  }
}

#aboutSkills button
{
  border-radius: 25px;
  background: none;
  border: 2px solid;
  border-color:  #00a4d6;
  color:  #00a4d6;
  font: inherit;
  line-height: 1;
  padding: .25em .5em;
  margin-right: 20px;
  margin-top: 5px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

#aboutContent
{
  display: flex;
  flex-direction: row;
}

#aboutIntro
{
  text-align: center;
}

#summary_section
{
  font-size: 80%;
 
}

.highlight
{
  text-shadow: 50%;
  color: #00a4d6;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
}

@media (max-width: 768px) {

  #about
  {
    padding-top: 100px;
  }
  .scrollButtons,
  .linkButtons {
      display: none; /* Hide scroll and link buttons on desktop */
  }

  .hamburger-menu {
      display: block; /* Show hamburger menu on mobile */
      margin-right: 20px; /* Adjust spacing */
  }


  #aboutDescription,
  #aboutSkills {
      max-width: 100%;
      width: auto;
      margin-right: 0;
  }

  #aboutContent {
    display: flex;
    flex-direction: column; /* Change to column for smaller screens */
    align-items: center;
    text-align: center; /* Center text for smaller screens */
}
}